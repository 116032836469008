import axios from "axios";

const client = axios.create({
  baseURL: process.env.REACT_APP_BE_API_URL,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  withXSRFToken: true,
});

export default client;

export const createReleaseList = async (
  playlistId,
  newPlaylistName,
  startDate,
  endDate
) => {
  try {
    const response = await client.post("/spotify/release-list/", {
      playlist_id: playlistId,
      new_playlist_name: newPlaylistName,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
    });
    return response;
  } catch (error) {
    console.error("There was an error fetching the release list:", error);
  }
};

export const logout = async () => {
  try {
    const response = await client.post("/spotify/logout/");
    return response.data;
  } catch (error) {
    console.error("There was an error logging out:", error);
  }
};

export const fetchPlaylists = async () => {
  try {
    const response = await client.get("/spotify/playlists/");
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the playlists:", error);
    throw error;
  }
};
