import { Button } from "src/shadcn/components/ui/button";
import spotifyGreenLogo from "../assets/images/Spotify_Primary_Logo_RGB_Green.png";
import spotifyWhiteLogo from "../assets/images/Spotify_Primary_Logo_RGB_White.png";

export default function Login() {
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BE_API_URL}/spotify/login/`;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background p-4">
      <img
        src={spotifyGreenLogo}
        alt="Spotify Green Logo"
        className="w-16 h-16 mb-6"
      />
      <h1 className="text-2xl font-bold text-center mb-8 w-[240px]">
        Connect your Spotify account to get started
      </h1>
      <Button
        className="w-[240px] bg-[#1DB954] hover:bg-[#1ed760] text-white font-semibold py-2 px-4 rounded-full flex items-center justify-center"
        onClick={handleLogin}
      >
        <img
          src={spotifyWhiteLogo}
          alt="Spotify White Logo"
          className="w-5 h-5 mr-2"
        />
        Login with Spotify
      </Button>
    </div>
  );
}
