import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import api from "../api";

function ProtectedRoute({ element }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        // Replace '/auth/check' with your actual route that verifies if the user is logged in
        const response = await api.get("/auth/check/");
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Authentication check failed:", error);
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkAuthentication();
  }, []);

  if (isLoading) {
    // Optional: Render a loading spinner or something similar while checking auth status
    return <div>Loading...</div>;
  }

  return isAuthenticated ? element : <Navigate to="/login" replace />;
}

export default ProtectedRoute;
