"use client";

import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import React from "react";

import { Button } from "src/shadcn/components/ui/button";
import { Calendar } from "src/shadcn/components/ui/calendar";
import { FormControl } from "src/shadcn/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/shadcn/components/ui/popover";
import { cn } from "src/shadcn/lib/utils";

export function CalendarDateRangePicker({ startDate, endDate, onDatesChange }) {
  const [date, setDate] = React.useState({
    from: startDate || new Date(2023, 0, 20),
    to: endDate || addDays(new Date(2023, 0, 20), 20),
  });

  const handleSelect = (newDate) => {
    setDate(newDate);
    onDatesChange(newDate);
  };

  const sixMonthsAgo = addDays(new Date(), -180);

  return (
    <div className={cn("grid")}>
      <Popover>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              id="date"
              variant={"outline"}
              className={cn(
                "w-[260px] justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, "LLL dd, y")} -{" "}
                    {format(date.to, "LLL dd, y")}
                  </>
                ) : (
                  format(date.from, "LLL dd, y")
                )
              ) : (
                <span>Pick a date</span>
              )}
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleSelect}
            numberOfMonths={2}
            max={30}
            disabled={(date) => date > new Date() || date < sixMonthsAgo}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
