import { TaskPage } from "../tasks/page";

function Home() {
  return (
    <>
      <TaskPage />
    </>
  );
}
export default Home;
