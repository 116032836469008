import { useNavigate } from "react-router-dom";
import { logout } from "src/api";
import { Button } from "../shadcn/components/ui/button";

function Logout() {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <Button variant="ghost" onClick={handleLogout}>
      Logout
    </Button>
  );
}

export default Logout;
